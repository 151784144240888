export default class Toolbar {
    constructor() {
        Toolbar.langSwitcher()
    }

    static langSwitcher() {
        const current_lang = '[data-id="wpiris_current-lang"]'
        const list_lang_item = '[data-id="wpiris_lang-list"] > * > a'

        //Init select lang
        $(window).on('load', () => {
            setTimeout(() => {
                const current_language = $('html').attr('lang').replace(/-.*/gi, '')
                $(current_lang).text(current_language)

                //Remove current language on list (init)
                $(list_lang_item).each(function () {
                    if ($(this).text().toLowerCase() === current_language.toLowerCase()) {
                        $(this).hide()
                    }
                })
            }, 300)
        })

        //Change Header Language on click
        $(list_lang_item).on('click', function () {
            $(list_lang_item).show()
            $(this).hide()
            $(current_lang).text($(this).text())
        })
    }
}
